$('.paging-sorter, .price-sorter').change(function () {
  $('.search-submit').submit()
})

$('.search-image').on('click', function () {
  $('.list-method').val('search_image')
  $('.search-submit').submit()
})
$('.search-detail').on('click', function () {
  $('.list-method').val('search_detail')
  $('.search-submit').submit()
})

$('.btn_favorite').on('click', function () {
  let flag = ($(this).attr('class').includes('active'))
  const attr = $(this).data('id')
  let locale = getParam('locale')
  if (locale == null){locale = 'ja'}
  $.ajax({
    url: flag ? `/products/likes/${$(this).data('id')}` : `/products/likes`,
    method: flag ? 'delete' : 'post',
    dataType: 'json',
    data: {id: attr, locale: locale },
    context: this
  })
      .done(function (res) {
        if (flag) {
          $(`.btn_favorite[data-id=${attr}]`).removeClass('active');
        } else {
          $(`.btn_favorite[data-id=${attr}]`).addClass('active');
        }
      })
      .fail(function (jqXHR, statusText, errorThrown) {
        alert(jqXHR.responseJSON.message)
      })

})

function getParam(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}
