// JavaScript Document


new Mmenu(
    document.querySelector('#js-mmenu'),
    {
      extensions: ['theme-dark', 'shadow-page'],
      setSelected: true,
      counters: true,
      searchfield: {
        placeholder: 'キーワードを入力/Enter keywords',
      },
      navbar: {
        title: 'JAPAN FARM AUCTION',
      },
      navbars: [
        {
          type: 'tabs',
          content: [
            '<a href="#panel-menu"><i class="fa fa-bars"></i> <span>Menu</span></a>',
            '<a href="#panel-account"><i class="fa fa-user"></i> <span>User</span></a>',
          ],
        },
        {
          position: 'bottom',
          content: [
            "<a class='fab fa-facebook' href='https://ja-jp.facebook.com/jfauc/'></a>"
          ],
        },
      ],
    },
    {
      searchfield: {
        clear: true,
      },
      navbars: {
        breadcrumbs: {
          removeFirst: true,
        },
      },
    }
);
