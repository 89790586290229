
import {uploadFile} from './direct_upload';

$(document).ready(function() {
  $('.working-hour').timepicker()
});


$(document).on('change', '#file-shops', function () {
  $(this).prop('disabled', true)

  $("#file_error").html("")
  let files = $("#file-shops")[0].files

  let registered_image = ($('.current-registered-image').size())
  if (registered_image + files.length > 4) {
    $("#file_error").html("※アップロード可能な画像は合計4枚までです").css('color', 'red')
    $('#file-shops').val(null)
    $(this).prop('disabled', false)
    return
  }
  for (let i = 0; i < files.length; i++) {
    let extension_type = ["image/jpeg", "image/png", "image/jpg"]
    if (!extension_type.includes(files[i]['type'])) {
      $("#file_error").html("※登録可能形式は、jpeg, jpg, pngのみです。").css('color', 'red')
      $(this).css('border: solid 2px red')
      $('#file-shops').val(null)
      $(this).prop('disabled', false)
      return
    }

    if (files[i]['size'] > 5242880) {
      $("#file_error").html("※5MB以上のファイルが含まれています。ファイルサイズは5MB以下のみ選択可能です").css('color', 'red')
      $(this).css('border: solid 2px red')
      $('#file-shops').val(null)
      $(this).prop('disabled', false)
      return
    }
  }

  let deferred = (new $.Deferred()).resolve();
  $("#file_error").html('<i class="fas fa-circle-notch fa-spin loading-spin"></i>').css('color', 'black')
  $.each(this.files, function (i, file) {
    deferred = deferred.then(uploadFile(file, '/dashboard/merchandise/merchandise_images', '#file-shops'));
  })

  deferred.then(function(){
    $("#file_error").html('')
    $('#file-shops').val(null).prop('disabled', false)
  });
})

