document.addEventListener("DOMContentLoaded", function() {
    setInterval(() => {
        // 現在のURLからロケールを取得
        const locale = window.location.pathname.split('/')[1];

        // 更新エリアのエレメントをチェック
        const updateArea = document.getElementById('update-area');
        if (!updateArea) {
            console.error('Update area not found, stopping interval');
            clearInterval(this);
            return;
        }

        fetch(`/dashboard/refresh_likes?locale=${locale}`)
            .then(response => response.text())
            .then(html => {
                updateArea.innerHTML = html;
                // カスタムイベントを発行
                updateArea.dispatchEvent(new CustomEvent('contentUpdated', { bubbles: true }));
            })
            .catch(error => console.error('Error loading the content: ', error));
    }, 60000); // 60000ミリ秒 = 1分
});
