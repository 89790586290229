$('.selectLanguage').change(function(){
  let url =$(this).val()
  if(url){
    let parse = (url.match(/<a href="([\w/:%#\$&\?\(\)~\.=\+\-]+)"/))
    window.location = (parse[1])
  }
  return false
})

$('.currencyChange').change(function(){
  let currency  = $(this).val()
  document.cookie = `currency=${currency}`
  location.reload()
})
