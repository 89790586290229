// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import $ from 'jquery';

global.$ = $
global.jQuery = $

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require("select2")

import 'src/js/mmenu.js'
import 'src/js/cmn_settings.js'
import 'src/js/merchandise.js'
import 'src/js/product.js'
import 'src/js/alert.js'
import 'src/js/dashboard.js'
import 'src/js/baguette_box.js'
import 'src/js/config.js'
import 'src/js/jquery.matchHeight-min.js'
import 'src/js/home_matchheight.js'
import 'src/js/jquery.timepicker.min.js'
import 'src/js/shop.js'
import 'src/js/help.js'
import 'src/js/admin_merchandise.js'
import 'src/js/common.js'
import 'src/js/payment.js'
import 'src/js/lang.js'
import 'src/js/select2_user_set.js'


require("trix")
require("@rails/actiontext")

Turbo.session.drive = false



