import {uploadFile} from './direct_upload';


//全角から半角変換
//
$(document).on('change', '#merchandise_price', function () {
  let text = $(this).val();
  let hen = text.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) {
    return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
  });
  $(this).val(hen);
})


//
//Edit時はカテゴリの変更をdisabledにしておく
//
$(document).ready(function () {
  let path = location.pathname.match(/\/merchandise\/\d+/) ? "render_edit" : "render_new"
  if (path == 'render_edit') {
    $("input:radio[name='category']").prop('disabled', true)
    $("input:radio[name='subcategory']").prop('disabled', true)
  }
})


function getFiseSize(file_size) {
  var str;

  // 単位
  var unit = ['byte', 'KB', 'MB', 'GB', 'TB'];

  for (var i = 0; i < unit.length; i++) {
    if (file_size < 1024 || i == unit.length - 1) {
      if (i == 0) {
        // カンマ付与
        var integer = file_size.toString().replace(/([0-9]{1,3})(?=(?:[0-9]{3})+$)/g, '$1,');
        str = integer + unit[i];
      } else {
        // 小数点第2位は切り捨て
        file_size = Math.floor(file_size * 100) / 100;
        // 整数と小数に分割
        var num = file_size.toString().split('.');
        // カンマ付与
        var integer = num[0].replace(/([0-9]{1,3})(?=(?:[0-9]{3})+$)/g, '$1,');
        if (num[1]) {
          file_size = integer + '.' + num[1];
        }
        str = file_size + unit[i];
      }
      break;
    }
    file_size = file_size / 1024;
  }

  return str;
}

$(function () {
  $('.directUpload').find("input:file").each(function (i, elem) {
    var fileInput = $(elem);
    var form = $(fileInput.parents('form:first'));
    var submitButton = form.find('input[type="submit"]');
    var progressBar = $("<div class='bar'></div>");
    var barContainer = $("<div class='progress'></div>").append(progressBar);
    fileInput.after(barContainer);
    fileInput.fileupload({
      fileInput: fileInput,
      url: form.data('url'),
      type: 'POST',
      autoUpload: true,
      formData: form.data('form-data'),
      paramName: 'file', // S3 does not like nested name fields i.e. name="user[avatar_url]"
      dataType: 'XML',  // S3 returns XML if success_action_status is set to 201
      replaceFileInput: false,
      progressall: function (e, data) {
        var progress = parseInt(data.loaded / data.total * 100, 10);
        progressBar.css('width', progress + '%')
      },
      start: function (e) {
        submitButton.prop('disabled', true);

        progressBar.css('background', 'green').css('display', 'block').css('width', '0%').text("Loading...");
      },
      done: function (e, data) {
        submitButton.prop('disabled', false);
        progressBar.text("Uploading done");

        // extract key and generate URL from response
        var key = $(data.jqXHR.responseXML).find("Key").text();
        var url = '//' + form.data('host') + '/' + key;

        // create hidden field
        var input = $("<input />", {type: 'hidden', name: fileInput.attr('name'), value: url})
        form.append(input);
      },
      fail: function (e, data) {
        submitButton.prop('disabled', false);

        progressBar.css("background", "red").text("Failed");
      }
    });
  });
});


//
//モデル名選択
//
$(document).on('change', '.model-selector', function () {
  if ($(this).val() == '1') {
    $('.model-selector').remove()
    $('.brand-selector').after("<div class='pure-g'></div><input type='text' class='pure-input-1 model-input' placeholder='型番記入' name='merchandise[model_number_user_input]' ></div>")
  }
})


//イメージ削除
$(document).on('click', ".fa-times", function () {
  let id = $(this).parents('li').data('id')
  if (id) {
    $(this).parents('li').remove()
  }
  $.ajax({
    url: '/dashboard/merchandise/draft_image_delete',
    type: 'post',
    dataType: 'json',
    data: {id: id},
    context: this
  })
      .done(function (res) {
      })
      .fail(function (jqXHR, statusText, errorThrown) {
      });
})


$(document).on('change', '#file-merchandise', function () {
  $(this).prop('disabled', true)

  $("#file_error").html("")
  let files = $("#file-merchandise")[0].files

  let registered_image = ($('.current-registered-image').length)

  if (registered_image + files.length > 30) {
    $("#file_error").html("※アップロード可能なファイルは30枚までです").css('color', 'red')
    $('#file-merchandise').val(null)
    $(this).prop('disabled', false)
    return
  }
  for (let i = 0; i < files.length; i++) {
    let extension_type = ["image/jpeg", "image/png", "image/jpg"]
    if (!extension_type.includes(files[i]['type'])) {
      $("#file_error").html("※登録可能形式は、jpeg, jpg, pngのみです。").css('color', 'red')
      $(this).css('border: solid 2px red')
      $('#file-merchandise').val(null)
      $(this).prop('disabled', false)
      return
    }
    if (files[i]['size'] > 5242880) {
      $("#file_error").html("※5MB以上のファイルが含まれています。ファイルサイズは5MB以下のみ選択可能です").css('color', 'red')
      $(this).css('border: solid 2px red')
      $('#file-merchandise').val(null)
      $(this).prop('disabled', false)
      return
    }
  }

  let deferred = (new $.Deferred()).resolve();
  $("#file_error").html('<i class="fas fa-circle-notch fa-spin loading-spin"></i>').css('color', 'black')
  $.each(this.files, function (i, file) {
    let merchandise_id = location.href.match(/merchandise\/(\d+)/)
    merchandise_id = merchandise_id ? merchandise_id[1] : null
    console.log(merchandise_id)
    deferred = deferred.then(uploadFile(merchandise_id, file, '/dashboard/merchandise/merchandise_images', '#file-merchandise'));
  })

  deferred.then(function () {
    $("#file_error").html('')
    $('#file-merchandise').val(null).prop('disabled', false)
  });
})





