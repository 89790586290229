import { DirectUpload } from "@rails/activestorage"

export const uploadFile = (merchandise_id, file, targetUrl, selector) => {
  // your form needs the file_field direct_upload: true, which
  //  provides data-direct-upload-url
  const input = document.querySelector('input[type=file]')
  const url = input.dataset.directUploadUrl
  const upload = new DirectUpload(file, url)

  return function() {
    let ret = new $.Deferred()
    upload.create((error, blob) => {
      if (error) {
        ret.reject()
        // Handle the error
      } else {
        // Add an appropriately-named hidden input to the form with a
        //  value of blob.signed_id so that the blob ids will be
        //  transmitted in the normal upload flow
        const hiddenField = document.createElement('input')
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("value", blob.signed_id);
        hiddenField.name = input.name
        document.querySelector(selector).appendChild(hiddenField)
        console.log(blob)
        let promise= upload_file_to_display(merchandise_id, blob, targetUrl)
        promise.done(function(){
          ret.resolve()
        })
      }
    })
    return ret.promise();
  }
}

export function upload_file_to_display(merchandise_id, blob, url){
  let d = new $.Deferred;
  $.ajax({
    url: url,
    method: 'post',
    data: { merchandise_id: merchandise_id, id: blob.id , signed_id:  blob.signed_id},
    context: this
  })
      .done(function (res) {
        d.resolve();
      })
      .fail(function (jqXHR, statusText, errorThrown) {
        d.reject();
      })
  return d.promise();
}

