function delay(callback, ms) {
   timer = 0;
  return function() {
    var context = this, args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      callback.apply(context, args);
    }, ms || 0);
  };
}

$('#user-id-input').keyup(delay(function (e) {
  let id = $(this).val()
  $('.name-company-display').remove()
  if (id != '') {
    $.ajax({
      url: '/adminusers/merchandise/user_id_to_name/' + id,
      dataType: 'json',
      context: this
    })
        .done(function (res) {
          $(this).parent('td').append(`<p class="name-company-display">${res['message']}</p>`)
        })
        .fail(function (jqXHR, statusText, errorThrown) {
          $(this).parent('td').append(`<p class="name-company-display">該当がありません</p>`)
        });
  }

}, 500));
