$('.language_setting_mobile').on('click',function(){
  let url = $(this).data('lang')
  if(url){
    let parse = (url.match(/<a href="([\w/:%#\$&\?\(\)~\.=\+\-]+)"/))
    window.location = (parse[1])
  }
  return false
})



$('.currency_setting_mobile').on('click',function(){
  $('#currency').val($(this).data('cur'))
  $('.currency_mobile').submit()
})






